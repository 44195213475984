exports.config = {
    framework: 'jasmine2',
    // seleniumAddress: 'http://localhost:4444/wd/hub',
    useAllAngular2AppRoots: true,
    specs: [
        'fms_app/tests/test-farm.js'
    ],
    capabilities: {
        'browserName': 'firefox'
    },
    // directConnect: true,
    jasmineNodeOpts: {
        defaultTimeoutInterval: 25000
    },
    onPrepare: function() {
        var jasmineReporters = require('../../../node_modules/jasmine-reporters');
        jasmine.getEnv().addReporter(new jasmineReporters.JUnitXmlReporter(null, true, true)
        );
    }
};
(function (angular) {
    'use strict';
    angular.module('CropBookApp')
        .run(['$http', '$rootScope', '$routeParams', '$location', '$mdDialog', 'UserSettingsFactory', 'DateTimeHelperFactory', CropBookRunConfig]);

    function CropBookRunConfig($http, $rootScope, $routeParams, $location, $mdDialog, UserSettingsFactory, DateTimeHelperFactory) {
        $http.defaults.xsrfCookieName = 'csrftoken';
        $http.defaults.xsrfHeaderName = 'X-CSRFToken';

        UserSettingsFactory.get(function (result) {
            $rootScope.user_settings = result;
            $rootScope.userSettingDateFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToAngular($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_FORMAT);
            $rootScope.userSettingDateTimeFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToAngular($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_TIME_FORMAT);
            $rootScope.userSettingDateFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToAngular($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_FORMAT);


            $rootScope.userSettingDateFormatMoment = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_FORMAT);
            $rootScope.userSettingDateTimeFormatMoment = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_TIME_FORMAT);
            $rootScope.userSettingDateFormatMoment = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_FORMAT);

        });
    }

})(angular);



angular.module('CropBookApp')
    .controller('CropBookDetailController', CropBookDetailController);

function CropBookDetailController($scope,
                                  $rootScope,
                                  $mdDialog,
                                  $filter,
                                  $khMessage,
                                  $khPulse,
                                  leafletData,
                                  NgTableParams,
                                  FieldPlotCropFactory,
                                  DateTimeHelperFactory,
                                  parentControllerData,
                                  plot_header_name,
                                  $timeout,
                                  WeatherService,
                                  WeatherUtilsService,
                                  PhoenixService) {

    $scope.khIcon = $rootScope.khIcon;
    $scope.plot_header_name = plot_header_name;
    $scope.currentView = 'tableView';
    $scope.center = {
        lat: undefined,
        lng: null,
        zoom: undefined
    };
    var mapid = 'detailMap';
    var process_name = '';

    function getAvailableParams(apiData) {
        return WeatherUtilsService.getParametersNamevalueDictList(apiData.metadata.parameters);
    }

    function getActualDailyData(queryDict, slug, type) {
        WeatherService.getWeatherData('blended-data', {'type': type, 'slug': slug}, queryDict).then(function (apiData) {
            if (apiData.data && apiData.data.length > 0) {
                $scope.currentChartTableData = apiData.data;
                $scope.availableParams = getAvailableParams(apiData);
                $timeout(buildChart, 1);
                $timeout(buildChart, 10);
                $scope.weather_status = '';
            }
        }, function () {

        });
    }

    function removeNonRequiredParamsFromChartParams(chartParams) {
        chartParams = WeatherUtilsService.removeElementFromArray(chartParams, 'wind_direction');
        return chartParams;
    }

    function buildChart() {
        $scope.xAxisFormat = {
            type: 'datetime',
            labels: {
                format: '{value: %e %b, %Y}'
            }
        };
        var chartParams = ['total_precipitation', 'mean_temperature', 'mean_relative_humidity'];

        chartParams = removeNonRequiredParamsFromChartParams(chartParams);
        $scope.chartConfiguration = WeatherUtilsService.getBaseChartConfig();
        WeatherUtilsService.getChartConfig($scope, chartParams, $scope.xAxisFormat);
        var extraChartSettings = {
            'conversionRequired': true,
            'conversionMethod': parseInt,
            'xyValuePush': true,
            'includeWindDirection': true
        };
        WeatherUtilsService.pushListDataInChart($scope, $scope.currentChartTableData, chartParams, extraChartSettings);
    }

    loadCropDetailAPIData();

    function loadCropDetailAPIData() {
        $khPulse.show();
        $scope.showEmptyState = false;
        var fpc_slug = parentControllerData.plotData;
        FieldPlotCropFactory.get({'field_plot_crop_slug': fpc_slug, 'data_format': 'geojson'}, function (data) {
            var queryParamDict = {};
            queryParamDict['parameters'] = "total_precipitation,mean_relative_humidity,mean_temperature";
            queryParamDict['params_as_daily'] = "1";
            queryParamDict['account_key'] = "kisanhub-account";

            var startdate = data.properties.plot_expected_sowing_date;
            var enddate = data.properties.plot_expected_harvest_date;
            var moment_startdate = moment(startdate).subtract(15, 'days');

            startdate = moment_startdate.format('YYYY-MM-DD');
            if (enddate === null || angular.isUndefined(enddate)) {
                var moment_enddate = moment().add(15, 'days');
            }
            else {
                var moment_enddate = moment(enddate).add(15, 'days');

            }
            enddate = moment_enddate.format('YYYY-MM-DD');
            queryParamDict['start_date'] = startdate;
            queryParamDict['end_date'] = enddate;

            getActualDailyData(queryParamDict, fpc_slug, 'plot');
            var params = {
                'plot': fpc_slug
            };
            $scope.plot_summary_data = data;
            $scope.plot_header_name = $scope.plot_summary_data.plot_name;
            $rootScope.cropbook_plotdata_geoJson = data;
            leafletData.getMap(mapid).then(function (map) {
                $rootScope.cropbook_plotdata_geoJsonLayer = createGeoJsonPlotPolygonLayer(data, $rootScope, leafletData, mapid);
                $rootScope.cropbook_leaflet_plot_layer_id = $rootScope.cropbook_plotdata_geoJsonLayer._leaflet_id;
                map.addLayer($rootScope.cropbook_plotdata_geoJsonLayer);
                map.fitBounds($rootScope.cropbook_plotdata_geoJsonLayer.getBounds(), {padding: [100, 100]});
                $rootScope.cropbook_plotdata_geoJsonLayer.bringToFront();
                $khPulse.hide();
            });

            // load_kisansat_field_data(SatellitePublishedDatasetResource, data.properties.field_slug, $scope);

        });

        function createGeoJsonPlotPolygonLayer(result, $rootScope, leafletData, mapid) {
            var getStyle = function (feature) {
                var fillcolor = feature.properties.colour_code;
                return {
                    color: fillcolor,
                    fillColor: fillcolor,
                    weight: 4,
                    opacity: 1,
                    fillOpacity: 0.7
                };
            };
            return (L.geoJson(result, {
                name: 'Plot',
                layer: 'PlotLayer',
                group: 'FarmField',
                onEachFeature: onEachFeature,
                style: getStyle
            }));


            function onEachFeature(feature, layer) {
                layer.on({click: zoomToFeature});
            }

            function zoomToFeature(event, args) {
                leafletData.getMap(mapid).then(function (map) {
                    map.fitBounds(event.target.getBounds(), {padding: [100, 100]});
                    $rootScope.selectedFeature = event.target;
                    $rootScope.originalFeature = event.target;
                    $rootScope.$broadcast("changedSelectedFeature");
                });
            }
        }

        $scope.querystring = {
            plot: fpc_slug,
            growing_season: $rootScope.selected_growing_season.value
        };
    }

    $rootScope.$on('editedFieldOperation', function (object) {
        getOperations();
    });

    $scope.lineChartConfig = {
        exporting: {
            enabled: false
        },
        lang: {
            noData: 'Loading Data...'
        },
        title: {
            text: '',
            style: {
                display: 'none'
            }
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            title: {
                text: null
            }
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: ''
        },
        legend: {
            style: {
                display: 'none'
            }
        },
        series: []
    };

    function create_field_progress_chart(process_name) {
        var overlayLayers = $scope.layers['overlays'];
        var ranges = [];
        var averages = [];
        for (var prop in overlayLayers) {
            var overylay_layer = overlayLayers[prop];
            if (overylay_layer.metadata.statistics !== null && overylay_layer.metadata.service.processing_type.process_name === process_name) {
                if (overylay_layer.metadata.statistics.min_value > 0 && overylay_layer.metadata.statistics.max_value < 1) {
                    ranges.push([overylay_layer.metadata.acquisition_timestamp, overylay_layer.metadata.statistics.min_value, overylay_layer.metadata.statistics.max_value]);
                    averages.push([overylay_layer.metadata.acquisition_timestamp, overylay_layer.metadata.statistics.average_value]);
                }
            }
        }
        var field_progress_line_chart_data = [
            {
                name: 'Average ' + process_name,
                data: averages,
                zIndex: 1,
                marker: {
                    fillColor: 'white',
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[0]
                }
            },
            {
                name: 'Range',
                data: ranges,
                type: 'arearange',
                lineWidth: 0,
                linkedTo: ':previous',
                color: Highcharts.getOptions().colors[0],
                fillOpacity: 0.3,
                zIndex: 0,
                marker: {
                    enabled: false
                }
            }
        ];
        $scope.lineChartConfig.series = field_progress_line_chart_data;
    }

    $scope.changeSelectedLayer = function (new_object_slug, new_process_name) {
        if (process_name !== new_process_name) {
            process_name = new_process_name;
            create_field_progress_chart(process_name);
        }
    };

    $scope.fieldDiariesListCard = [{
        card_title: $filter('translate')('diary.field_diary_entries'),
        kh_card_content_template: '/static/assets/khapps/fms_app/partials/farmfielddiary/diary/view_crop_monitoring.html',
        card_flex: 100,
        show_progress_bar: true
    }];

    $scope.dialog_header_footer_functions = {
        isDialogEditable: false,
        onDialogEdit: onDialogEdit,

        isContentDownloadable: false,
        onContentDownload: onContentDownload,

        isExportableAsExcel: false,
        onExportAsExcel: onExportAsExcel,
        isExportableAsPDF: false,
        onExportAsPDF: onExportAsPDF,

        isSharableByEmail: false,
        onShareByEmail: onShareByEmail,

        isSharableByTwitter: false,
        onSharebytwitter: onSharebytwitter
    };

    $scope.PhoenixCMDs = (app_link) => {
        PhoenixService.getRefreshToken().then( (token)=> {
            window.location = `${PhoenixService.getHostUrl}${app_link}/?token=${token}&plot_id=${$scope.plot_summary_data.properties.plot_id}`;
        });
    };

    function onDialogEdit() {
        $khMessage.show('onDialogEdit');
    }

    function onContentDownload() {
        $khMessage.show('onContentDownload');
    }

    function onExportAsExcel() {
        $khMessage.show('exportAsExcel');
    }

    function onExportAsPDF() {
        $khMessage.show('exportAsPDF');
    }

    function onShareByEmail() {
        $khMessage.show('onShareByEmail');
    }

    function onSharebytwitter() {
        $khMessage.show('onSharebytwitter');
    }

    $scope.redirectPhoenixOperations = function() {
        var plot_id = $scope.plot_summary_data.properties.plot_id;
        var plot_name = $scope.plot_summary_data.properties.plot_name;
        PhoenixService.getRefreshToken().then((token) => {
            window.location = `${PhoenixService.getHostUrl}/farm/operation/?plot_id=${plot_id}&plot_name=${plot_name}&token=${token}`;
        });
        };
}

angular.module('content_delivery_system').controller('ContentDeliveryController',
    function ($scope, $filter, $khMessage, ContentDeliveryFactory, TeamsListFactory, $mdDialog, GenericUtilsService) {
        $scope.message = {'content': '', 'dlt_template_id': ''};

        TeamsListFactory.query({}, function (teams) {
            $scope.teams_list = teams;
        });
        // selected has to be a dict, in order to have 2 way binding with directive
        $scope.selected = {
            selected: []
        }
        $scope.sendMessage = function () {
            $scope.message.team = $scope.selected.selected;
            ContentDeliveryFactory.send($scope.message, function (res) {
                $khMessage.show('Short message recorded successfully.', 3000);
                $mdDialog.hide();
            }, function (errr) {
                GenericUtilsService.handleError($filter, $khMessage, errr, 10000);
            });
        };
        $scope.cancel = function () {
            $mdDialog.hide();
        };
    });

(function (angular) {
    'use strict';
    angular.module('CropBookApp')
        .factory('LandingChartsFactory', LandingChartsFactory);
    LandingChartsFactory.$inject = ['$resource'];

    function LandingChartsFactory($resource) {
        var url = '/api/v1.0/farm-reports/charts';
        var queryParamDict = {
            farm: '@farm',
            numberofseasons: '@numberofseasons'
        };
        return $resource(url, {},
            {
                get: {
                    params: queryParamDict,
                    url: url
                }
            }
        );
    }

})(angular);





export default angular.module('CropBookApp', [
    'KhBase',
    'KhChart',
    'ApiUtils.ksat',
    'ApiUtils.fms.cropProgress',
    'ApiUtils.fms.farmfield',
    'ApiUtils.fms.fieldplotcrop',
    'ApiUtils.dms.cropData',
    'ApiUtils.dms.genericProducts',
]);
